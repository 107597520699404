<template>
  <div>
    <h1>Abgabenhelfer</h1>
    <p>
      Dieses Tool unterstützt die Korrektur und Rückgabe der Studentenabgaben
      über OLAT. <br />
      Es unterteilt in Korrekturgruppen oder generiert ein Zip File, welches in
      OLAT zur Rückgabe hochgeladen werden kann.
    </p>
    <br />
    <p>Aufteilung der Abgaben in Korrekturgruppen:</p>
    <Button class="calculate-button" @click.prevent="$router.push('/Group')">
      Gruppieren
    </Button>
    <br /><br />
    <br />
    <p>Rückgabe der korrigierten/bewerteten PDF Dateien über OLAT:</p>
    <Button class="calculate-button" @click.prevent="$router.push('/Olat')">
      Rückgabe
    </Button>
  </div>
</template>
